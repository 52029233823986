const Fadeins = gsap.utils.toArray('.js-fadein');
const FadeinLefts = gsap.utils.toArray('.js-fadein-left');
const FadeinRights = gsap.utils.toArray('.js-fadein-right');
const FadeinBottoms = gsap.utils.toArray('.js-fadein-bottom');
//stagger
const FadeinStagger01 = gsap.utils.toArray('.js-fadein-stagger01'); //top
const FadeinStagger02 = gsap.utils.toArray('.js-fadein-stagger02'); //top
const FadeinStagger03 = gsap.utils.toArray('.js-fadein-stagger03'); //top
const FadeinStagger04 = gsap.utils.toArray('.js-fadein-stagger04'); //top

// Fadein
if(Fadeins.length){
  Fadeins.forEach((Fadein) => {
    gsap.fromTo(Fadein,
      {
        autoAlpha: 0
      },
      {
        autoAlpha: 1,
        duration: 2,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: Fadein,
          start: 'top 75%',
        }
      }
    );
  });
}

// Fadein Left
if(FadeinLefts.length){
  FadeinLefts.forEach((FadeinLeft) => {
    gsap.fromTo(FadeinLeft,
      {
        x: -25,
        autoAlpha: 0
      },
      {
        x: 0,
        autoAlpha: 1,
        duration: 2,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: FadeinLeft,
          start: 'top 75%'
        }
      }
    );
  });
}

// Fadein Right
if(FadeinRights.length){
  FadeinRights.forEach((FadeinRight) => {
    gsap.fromTo(FadeinRight,
      {
        x: 25,
        autoAlpha: 0
      },
      {
        x: 0,
        autoAlpha: 1,
        duration: 2,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: FadeinRight,
          start: 'top 75%'
        }
      }
    );
  });
}

// Fadein Bottom
if(FadeinBottoms.length){
  FadeinBottoms.forEach((FadeinBottom) => {
    gsap.fromTo(FadeinBottom,
      {
        y: 25,
        autoAlpha: 0
      },
      {
        y: 0,
        autoAlpha: 1,
        duration: 2,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: FadeinBottom,
          start: 'top 75%'
        }
      }
    );
  });
}

// Fadein Stagger
if(FadeinStagger01.length){
  gsap.fromTo(FadeinStagger01,
    {
      autoAlpha: 0
    },
    {
      autoAlpha: 1,
      duration: 2,
      ease: 'power2.out',
      stagger: .4,
      scrollTrigger: {
        trigger: FadeinStagger01,
        start: 'top 75%',
      },
    }
  );
}
if(FadeinStagger02.length){
  gsap.fromTo(FadeinStagger02,
    {
      autoAlpha: 0
    },
    {
      autoAlpha: 1,
      duration: 2,
      ease: 'power2.out',
      stagger: .4,
      scrollTrigger: {
        trigger: FadeinStagger02,
        start: 'top 75%',
      },
    }
  );
}
if(FadeinStagger03.length){
  gsap.fromTo(FadeinStagger03,
    {
      autoAlpha: 0
    },
    {
      autoAlpha: 1,
      duration: 2,
      ease: 'power2.out',
      stagger: .4,
      scrollTrigger: {
        trigger: FadeinStagger03,
        start: 'top 75%',
      },
    }
  );
}
if(FadeinStagger04.length){
  gsap.fromTo(FadeinStagger04,
    {
      autoAlpha: 0
    },
    {
      autoAlpha: 1,
      duration: 2,
      ease: 'power2.out',
      stagger: .4,
      scrollTrigger: {
        trigger: FadeinStagger04,
        start: 'top 75%',
      },
    }
  );
}